<template>
    <div id="app" v-cloak>
        <form>
            <loading ref="loadingComponent"></loading>
            <div class="column">
                <div class="row">
                    <h2>Vertragsdaten</h2>
                </div>
                <div class="row">
                    <label>Land (Datenbasis):</label>
                    <select v-model="scform.country" @change="onCountryChange" class="small" required>
                        <option v-for="option in options.countries" :value="option" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div class="row" v-show="scform.country.value === 'ch'">
                    <label>Kanton:</label>
                    <select v-model="scform.canton" class="small" @change="onDataChange">
                        <option v-for="option in options.cantons" :value="option" :key="option.code">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <div class="row">
                    <select v-model="scform.car" @change="onCarChange" required>
                        <option value="0" disabled>Fahrzeug wählen</option>
                        <option v-for="option in options.cars" :value="option" :key="option.id">
                            {{ option.model_variant.model.make.name }} {{ option.model_variant.model.name }} {{ option.model_variant.name || '' }}({{ option.model_year }})
                        </option>
                    </select>
                </div>
                <div class="row">
                    <select v-model="scform.color" @change="onDataChange('colorChanged', $event)" :disabled="cant_calc" required>
                        <option value="0" disabled>Farbe wählen</option>
                        <option v-for="option in options.colors" :value="option" :key="option.name">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <div class="row">
                    <label for="fixture">Ausstattungspaket:</label>
                    <select id="fixture" v-model="scform.fixture" class="small"
                            @change="onDataChange('fixtureLevelChanged', $event)" :disabled="cant_calc" required>
                        <option v-for="option in options.fixtures" :value="option" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div class="row">
                    <label for="term">Vertragslaufzeit in Monaten:</label>
                    <select id="term" v-model="scform.termInMonth" class="small"
                            @change="onDataChange('termChanged', $event)" :disabled="cant_calc" required>
                        <option value="0" disabled>Laufzeit wählen</option>
                        <option v-for="option in options.termsInMonth" :value="option" :key="option.text">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div class="row">
                    <label for="km-per-year">{{ distanceName }}:</label>
                    <select id="km-per-year" v-model="scform.kmPerYear" class="small"
                            @change="onDataChange('distanceChanged', $event)" :disabled="cant_calc" required>
                        <option v-for="option in distances" :value="option" :key="option.text">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div class="row">
                    <label for="min-ip-amount">Mindestanzahlung:</label>
                    <vue-numeric id="min-ip-amount" separator="." v-model.number="scform.minIpAmount" class="small"
                                 disabled/>
                </div>
                <div class="row">
                    <label for="ip-amount">Anzahlung:</label>
                    <vue-numeric id="ip-amount" separator="." v-model.number="scform.ipAmount" class="small"
                                 placeholder="Anzahlung" @input="onDataChange" @change.native="onIpAmountChange"
                                 :disabled="cant_calc" required/>
                </div>
                <template v-if="this.scform.car">
                    <div class="row" v-if="options.availAdditionalFixtures.length">
                        <strong>Zusatzausstattung</strong>
                    </div>
                    <div class="row additional-fixture-row" v-for="item in options.availAdditionalFixtures"
                         :key="`af_${item.type}_${item.id}`">
                        <div>
                            <label :for="`af_${item.type}_${item.id}`"
                                   class="additional-fixture-checkbox-label">{{ item.name }}</label>
                            <input :id="`af_${item.type}_${item.id}`" type="checkbox" :value="item"
                                   v-model="scform.additionalFixtures"
                                   @change="onDataChange('afChanged', $event)">
                        </div>
                        <div class="additional-fixture-costs">
                            <strong>{{ curr }} {{ item['increase_in_price']['pre'] }} mtl.</strong>
                        </div>
                    </div>
                </template>
                <template v-if="currentUser.is_superstaff">
                    <div class="row">
                        <strong>Zusatzausstattung manuell hinzufügen</strong>
                    </div>
                    <div class="row">
                        <input type="text" v-model="scform.additionalCustomFixture.name" placeholder="Bezeichnung"
                               :disabled="cant_calc">
                    </div>
                    <div class="row">
                        <label for="additional-custom-fixture-price">Preis:</label>
                        <vue-numeric id="additional-custom-fixture-price" v-model="scform.additionalCustomFixture.price"
                                     separator="." @input="customAfChange" class="small"
                                     :disabled="cant_calc || scform.additionalCustomFixture.name.length < 3"/>
                    </div>
                </template>
                <div class="row" v-if="options.availChargingEquip.length && this.scform.country.value !== 'eng'">
                    <strong>Ladezubehör</strong>
                </div>
                <div class="row charging-equip-row" v-for="item in options.availChargingEquip" :key="item.id">
                    <div>
                        <label :for="`ce_${item.id}`" class="charging-equip-checkbox-label">{{
                                item.description
                            }}</label>
                        <input :id="`ce_${item.id}`" type="checkbox" :value="item" v-model="scform.chargingEquip"
                               @change="onDataChange">
                    </div>
                    <div class="charging-equip-costs"><strong>{{ curr }} {{ item.price['pre'] }}</strong></div>
                </div>
                <template v-if="currentUser.is_superstaff">
                    <div class="row">
                        <strong>
                            Rabatt (brutto, direkt auf die Monatsrate):<br>
                            Wichtig: Der Rabatt darf erst ganz am Schluss gesetzt werden!
                        </strong>
                    </div>
                    <div class="row">
                        <input type="text" v-model="scform.specialDiscount.name" placeholder="Interne Rabattnotiz"
                               :disabled="cant_calc">
                    </div>
                    <div class="row">
                        <label for="special-discount-perc">Rabatt in %:</label>
                        <vue-numeric id="special-discount-perc" :minus="true" separator="." :precision="2" class="small"
                                     v-model.number="scform.specialDiscountPercValue"
                                     @input="specialDiscountPercChange"
                                     :disabled="cant_calc"/>
                    </div>
                    <div class="row">
                        <label for="special-discount">Rabatt in {{ currName }}:</label>
                        <vue-numeric id="special-discount" :minus="true" v-model.number="scform.specialDiscount.price"
                                     @input="onDataChange" class="small" separator="."
                                     :disabled="cant_calc || scform.specialDiscountPercValue !== 0"/>
                    </div>
                </template>
                <div class="row">
          <textarea v-model="scform.additionalInfos" class="additional-contract-infos"
                    placeholder="Zusatzvermerk"></textarea>
                </div>
                <div class="row">
                    <div class="inline-item" v-if="this.scform.country.value !== 'eng' && this.scform.country.value !== 'fr'">
                        <input id="tire-service" type="checkbox" v-model="scform.tireService" @change="onDataChange"
                               :disabled="cant_calc">
                        <label for="tire-service">Reifenpaket Plus</label>
                    </div>
                    <div class="inline-item">
                        <input id="delivery" type="checkbox" v-model="scform.delivery" @change="onDataChange"
                               :disabled="cant_calc">
                        <label for="delivery">Zustellung {{ curr }} {{ options.deliveryPrice.price['pre'] }}</label>
                    </div>
                    <template v-if="options.availExtraOptions.length !== 0">
                        <div class="inline-item">
                            <input id="remove_branding" type="checkbox" v-model="scform.extraOptions"
                                   :value="getExtra('remove_branding')" @change="onDataChange" :disabled="cant_calc">
                            <label for="remove_branding">
                                Entfall der Beklebung {{ curr }} {{ getExtra( 'remove_branding' )['increase_in_price']['pre'] }} mtl.
                            </label>
                        </div>
                        <div class="inline-item">
                            <input id="warranty_assistance" type="checkbox" v-model="scform.extraOptions"
                                   :value="getExtra('warranty_assistance')" @change="onDataChange"
                                   :disabled="cant_calc">
                            <label for="warranty_assistance">
                                Mobilitätsgarantie {{ curr }} {{ getExtra( 'warranty_assistance' )['increase_in_price']['pre'] }} mtl.
                            </label>
                        </div>
                    </template>
                </div>
                <div v-if="currentPriceResult" class="price-result">
                    <div class="row">
                        Monatsrate (brutto):<span class="price">{{ curr }} {{ currentPriceResult['fp_price']['pre'] }}</span>
                    </div>
                    <div class="row">
                        Monatsrate (netto):<span class="price after-tax">
                        {{ curr }} {{ Math.round(currentPriceResult['fp_price']['after']) }}
                    </span>
                    </div>
                    <div class="row">
                        Lieferzeit: <span class="delivery-time">{{ deliveryString }}</span>
                    </div>
                    <div class="row">
                        Einmalige Kosten: <span class="price">{{ curr }} {{ currentPriceResult['otc_price']['pre'].toLocaleString() }}</span>
                    </div>
                    <div class="row" v-if="this.scform.country.value === 'at' && currentPriceResult['acquisition_costs']['purchase_costs']">
                        Anschaffungskosten (brutto):
                        <span class="price">
                          {{ curr }} {{ currentPriceResult['acquisition_costs']['purchase_costs'].toLocaleString() }}
                        </span>
                    </div>
                    <div class="row">
                        Bruttolistenpreis:
                        <span class="price">
                            {{ curr }} {{ currentPriceResult['acquisition_costs']['gross_list_price'].toLocaleString() }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="row">
                    <h2>Kundendaten</h2>
                </div>
                <div class="row search-row">
                    <multiselect v-model="scform.searchValue"
                                 :options="options.searchData"
                                 :custom-label="nameWithEMail"
                                 :show-labels="false"
                                 placeholder="Lead/Kunde aus ERP wählen"
                                 label="name"
                                 track-by="name"
                                 @input="onSearchInputChange">
                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__title">{{ props.option.name }}</span>
                            <span class="option__badge">{{ props.option.badge_text }}</span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <span class="option__title">{{ props.option.name }}</span>
                            <span class="option__badge">{{ props.option.badge_text }}</span>
                        </template>
                    </multiselect>
                </div>
                <div class="row">
                    <div class="inline-item">
                      <input id="is-company" type="checkbox" v-model="scform.isCompany">
                      <label for="is-company">Unternehmen</label>
                    </div>
                  <div class="inline-item" v-if="scform.isCompany">
                    <select id="fleet-size" v-model="scform.fleetSize" class="small" required>
                      <option value="0" disabled>Flottengröße wählen</option>
                      <option v-for="option in options.fleetSizes" :value="option.value" :key="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                    <input v-model="scform.email" type="text" placeholder="E-Mail-Adresse"
                           @input="onCustomerFormInputChange" @change="onCustomerFormInputChange" required>
                </div>
                <div class="row">
                    <input v-model="scform.company" type="text" placeholder="Firma" maxlength="100">
                </div>
                <div class="row">
                    <select v-model="scform.gender">
                        <option value="">Geschlecht wählen</option>
                        <option v-for="option in options.gender" :value="option.value" :key="option.text">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div class="row">
                    <div class="inline-item textfield">
                        <input v-model="scform.titlePrefixed" type="text" placeholder="Titel vorangestellt"
                               maxlength="100"
                               @input="onCustomerFormInputChange" @change="onCustomerFormInputChange">
                    </div>
                    <div class="inline-item textfield">
                        <input v-model="scform.titleSuffixed" type="text" placeholder="Titel hintangestellt"
                               maxlength="100"
                               @input="onCustomerFormInputChange" @change="onCustomerFormInputChange">
                    </div>
                </div>
                <div class="row">
                    <input v-model="scform.firstName" type="text" placeholder="Vorname" maxlength="40"
                           @input="onCustomerFormInputChange" @change="onCustomerFormInputChange">
                </div>
                <div class="row">
                    <input v-model="scform.lastName" type="text" placeholder="Nachname" maxlength="50"
                           @input="onCustomerFormInputChange" @change="onCustomerFormInputChange">
                </div>
                <div class="row">
                    <input v-model="scform.street" type="text" placeholder="Straße" maxlength="100"
                           @input="onCustomerFormInputChange" @change="onCustomerFormInputChange">
                </div>
                <div class="row">
                    <input v-model="scform.zip" type="number" placeholder="PLZ" class="small"
                           @input="onCustomerFormInputChange" @change="onCustomerFormInputChange">
                </div>
                <div class="row">
                    <input v-model="scform.city" type="text" placeholder="Stadt" maxlength="30"
                           @input="onCustomerFormInputChange" @change="onCustomerFormInputChange">
                </div>
                <div class="row">
                    <input v-model="scform.phone" type="tel" placeholder="Telefonnummer">
                </div>
                <div class="row">
                    <input v-model="scform.mobile" type="tel" placeholder="Mobilnummer">
                </div>
                <div class="row">
                    <select v-model="scform.sales_channel" :disabled="team_disabled" required>
                        <option value="0">Vertriebskanal wählen</option>
                        <option v-for="option in options.saleschannels" :value="option.erpId" :key="option.erpId">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <div class="row">
                    <multiselect v-model="scform.tags"
                                 placeholder="Tags suchen und hinzufügen"
                                 label="name"
                                 track-by="name"
                                 :show-labels="false"
                                 :options="options.tags"
                                 :multiple="true">
                    </multiselect>
                </div>
                <div class="row">
                    <div class="inline-item">
                        <input id="signing_page" type="checkbox" v-model="scform.template_options.add_signing_page"
                               :disabled="cant_contract">
                        <label for="signing_page">Unterschriftsseite</label>
                    </div>
                    <div class="inline-item">
                        <input id="service_description" type="checkbox"
                               v-model="scform.template_options.add_service_description"
                               :disabled="cant_contract">
                        <label for="service_description">Leistungsbeschreibung</label>
                    </div>
                    <div class="inline-item">
                        <input id="terms_and_conditions" type="checkbox"
                               v-model="scform.template_options.add_terms_and_conditions"
                               :disabled="cant_contract">
                        <label for="terms_and_conditions">AGB</label>
                    </div>
                </div>
                <div class="row">
          <textarea v-model="scform.internalNotice" class="internal-notices"
                    placeholder="Interne Notiz hinzufügen"></textarea>
                </div>
                <div class="row submit-container">
                    <input class="submit-button contract" type="submit" value="Vertragsentwurf senden"
                           :disabled="cant_calc || cant_contract" @click="onSubmitButtonClick">
                </div>
            </div>
            <div class="column customer-grid">
                <h2>Kundeninfos</h2>
                <Grid v-if="customerGrid.showTable"
                      :heroes="customerGrid.data"
                      :columns="customerGrid.columns"
                      :pdf-dest="customerGrid.pdfDest"
                      :mail-dest="customerGrid.mailDest">
                </Grid>
                <div class="customer-message" v-if="customerGrid.showMsg">
                    <h3>Kundennachrichten:</h3>
                    <div>
                        <pre>{{ customerGrid.messages }}</pre>
                    </div>
                    <h3>Interne Notizen:</h3>
                    <div>
                        <pre>{{ customerGrid.internalNotices }}</pre>
                    </div>
                </div>
            </div>
        </form>
        <div class="column">
            <div class="row full-width">
                <h2>Mailtext</h2>
            </div>
            <div class="row full-width">
                <rich-text-editor ref="rtEditorComponent" v-model="scform.mailtext"></rich-text-editor>
            </div>
            <div class="row full-width">
                <h2>Zusätzliche Anhänge</h2>
            </div>
            <div class="row full-width">
                <base64-file-upload placeholder="Attachment 1" @uploaded="onUploaded"></base64-file-upload>
                <base64-file-upload placeholder="Attachment 2" @uploaded="onUploaded"></base64-file-upload>
                <base64-file-upload placeholder="Attachment 3" @uploaded="onUploaded"></base64-file-upload>
            </div>
        </div>
    </div>
</template>

<script>
import {
    findExtraOption,
    getCurrName,
    getCurrSymbol,
    getDeliveryString, getFixtureDesc,
    showConfirmPopup,
    showErrorPopup
} from '@/utils/utils';
import {
    clearDisabledAFState,
    clearForm,
    fillCustomFormData,
    setButtonStatus,
    showPrice
} from '@/utils/form-helper';
import * as df from '@/api/data';
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import RichTextEditor from '@/components/RichTextEditor';
import Grid from '@/components/Grid';
import Base64FileUpload from '@/components/Base64FileUpload';
import Loading from '@/components/Loading';
import { doSubmit } from '@/submit';
import { getCustomAF } from "@/api/data";

export default {
    name: 'App',
    data: function () {
        return {
            currentUser: { username: '', is_superstaff: false, mailsig: '' },
            scform: {
                searchValue: '',
                isCompany: false,
                fleetSize: '0',
                email: '',
                company: '',
                gender: '',
                titlePrefixed: '',
                titleSuffixed: '',
                firstName: '',
                lastName: '',
                zip: '',
                city: '',
                street: '',
                phone: '',
                mobile: '',
                sales_channel: 13,
                internalNotice: '',
                tags: [],
                template_options: {
                    add_signing_page: true,
                    add_service_description: true,
                    add_terms_and_conditions: true,
                },
                extraOptions: [],
                delivery: false,
                additionalAttachments: [],
                country: null,
                canton: { name: 'Zürich', code: 'zh' },
                car: null,
                color: null,
                fixture: null,
                chargingEquip: [],
                additionalFixtures: [],
                additionalCustomFixture: { type: 'custom', name: '', price: 0, increase_in_price: { pre: 0, after: 0 } },
                specialDiscount: { name: '', price: 0 },
                specialDiscountPercValue: 0.0,
                additionalInfos: '',
                termInMonth: null,
                kmPerYear: null,
                tireService: false,
                ipAmount: 0,
                minIpAmount: 0,
            },
            options: {
                iaInterval: null,
                fleetSizes: [
                    { value: '1', text: '1-5 Fahrzeuge' },
                    { value: '2', text: '6-20 Fahrzeuge' },
                    { value: '3', text: '21-50 Fahrzeuge' },
                    { value: '4', text: '>50 Fahrzeuge' },
                ],
                gender: [
                    { value: 'f', text: 'weiblich' },
                    { value: 'm', text: 'männlich' },
                    { value: 'd', text: 'inter/divers' },
                ],
                saleschannels: [],
                searchData: [],
                tags: [],
                countries: [
                    { value: 'at', text: 'Österreich' },
                    { value: 'de', text: 'Deutschland' },
                    { value: 'ch', text: 'Schweiz' },
                    { value: 'fr', text: 'Frankreich' },
                ],
                cantons: [
                    { name: 'Aargau', code: 'ag' },
                    { name: 'Appenzell Außerrhoden', code: 'ar' },
                    { name: 'Appenzell Innerrhoden', code: 'ai' },
                    { name: 'Basel-Landschaft', code: 'bl' },
                    { name: 'Basel-Stadt', code: 'bs' },
                    { name: 'Bern', code: 'be' },
                    { name: 'Freiburg', code: 'fr' },
                    { name: 'Genf', code: 'ge' },
                    { name: 'Glarus', code: 'gl' },
                    { name: 'Graubünden', code: 'gr' },
                    { name: 'Jura', code: 'ju' },
                    { name: 'Luzern', code: 'lu' },
                    { name: 'Neuenburg', code: 'ne' },
                    { name: 'Nidwalden', code: 'nw' },
                    { name: 'Obwalden', code: 'ow' },
                    { name: 'Schaffhausen', code: 'sh' },
                    { name: 'Schwyz', code: 'sz' },
                    { name: 'Solothurn', code: 'so' },
                    { name: 'St. Gallen', code: 'sg' },
                    { name: 'Tessin', code: 'ti' },
                    { name: 'Thurgau', code: 'tg' },
                    { name: 'Uri', code: 'ur' },
                    { name: 'Waadt', code: 'vd' },
                    { name: 'Wallis', code: 'vs' },
                    { name: 'Zürich', code: 'zh' },
                    { name: 'Zug', code: 'zg' },
                ],
                cars: [],
                colors: [],
                defaultFixtures: [
                    { value: 1, text: 'Silber' },
                    { value: 2, text: 'Gold' },
                    { value: 3, text: 'Platin' },
                ],
                fixtures: [
                    { value: 1, text: 'Silber' },
                    { value: 2, text: 'Gold' },
                    { value: 3, text: 'Platin' },
                ],
                fixtureDetails: null,
                availAdditionalFixtures: [],
                availExtraOptions: [],
                availChargingEquip: [],
                deliveryPrice: { price: 0 },
                termsInMonth: [
                    { value: 6, text: '6' },
                    { value: 12, text: '12' },
                    { value: 18, text: '18' },
                    { value: 24, text: '24' },
                    { value: 30, text: '30' },
                    { value: 36, text: '36' },
                    { value: 42, text: '42' },
                    { value: 48, text: '48' },
                    { value: 54, text: '54' },
                    { value: 60, text: '60' },
                    { value: 66, text: '66' },
                    { value: 72, text: '72' },
                ],
                kmsPerYear: [
                    { value: 5, text: '5.000' },
                    { value: 10, text: '10.000' },
                    { value: 15, text: '15.000' },
                    { value: 20, text: '20.000' },
                    { value: 25, text: '25.000' },
                    { value: 30, text: '30.000' },
                    { value: 35, text: '35.000' },
                    { value: 40, text: '40.000' },
                    { value: 45, text: '45.000' },
                    { value: 50, text: '50.000' },
                    { value: 55, text: '55.000' },
                    { value: 60, text: '60.000' },
                ],
                milesPerYear: [
                    { value: 5, text: '5.000' },
                    { value: 8, text: '8.000' },
                    { value: 10, text: '10.000' },
                    { value: 12, text: '12.000' },
                    { value: 15, text: '15.000' },
                    { value: 20, text: '20.000' },
                    { value: 25, text: '25.000' },
                    { value: 30, text: '30.000' },
                ],
            },
            customerGrid: {
                columns: [ 'date', 'car', 'months', 'km', 'ip', 'pdf', 'mail' ],
                data: [],
                pdfDest: `${ process.env.VUE_APP_IC_SERVICE_HOST }/media/pdfs/`,
                mailDest: `${ process.env.VUE_APP_IC_SERVICE_HOST }/media/pdfs/staffcalc_customer_mails/`,
                showTable: false,
                showMsg: false,
                messages: '',
                internalNotices: '',
            },
            cant_calc: true,
            cant_contract: true,
            team_disabled: false,
            price_request: null,
            currentPriceResult: null,
            originalPriceResult: null,
            complete_car: null,
        };
    },
    methods: {
        getExtra: function ( extra ) {
            return findExtraOption( this.options.availExtraOptions, extra );
        },
        onUploaded: function ( file_data ) {
            // push file data to additionalAttachments array
            this.scform.additionalAttachments.push( file_data );
        },
        onCustomerFormInputChange: function () {
            setButtonStatus( this );
        },
        onSearchInputChange: function () {
            clearForm( this );

            if ( 'is_lead' in this.scform.searchValue ) {
                this.customerGrid.showMsg = true;
                this.$refs.loadingComponent.isLoading = true;

                df.getLead( this.scform.searchValue.id ).then( ( result ) => {
                    fillCustomFormData( this, result );
                    this.customerGrid.data = result['pdf_offers'];
                    this.scform.tags = result['tags'];

                    this.customerGrid.showTable = !!this.customerGrid.data;
                    this.$refs.loadingComponent.isLoading = false;
                    this.team_disabled = !!result['sales_channel'];
                } );
            }

            if ( 'is_customer' in this.scform.searchValue ) {
                this.$refs.loadingComponent.isLoading = true;
                this.customerGrid.showMsg = true;

                df.getCustomer( this.scform.searchValue.id ).then( ( result ) => {
                    fillCustomFormData( this, result );
                    this.customerGrid.showTable = false;
                    this.$refs.loadingComponent.isLoading = false;

                    this.team_disabled = !!result['sales_channel'];
                } );
            }
        },
        customAfChange: function () {
            if ( !this.price_request || !this.scform.additionalCustomFixture.price) {
                return;
            }

            // make a shallow copy, set includeDetails to false and  remove additionalFixtures before sending
            const pr = { ...this.price_request, includeDetails: false, additionalFixtures: [] };

            const data = {
                price_request: pr,
                custom_af: this.scform.additionalCustomFixture,
            }

            getCustomAF( data ).then( ( resp ) => {
                this.scform.additionalCustomFixture.increase_in_price = resp['increase_in_price'];
                this.onDataChange();
            } );
        },
        onCountryChange: function () {
            this.$refs.loadingComponent.isLoading = true;
            // get saleschannels
            df.getSalesChannels( this.scform.country.value ).then( ( result ) => {
                this.options.saleschannels = result;
            });

            df.getCars( this.scform.country.value ).then( ( result ) => {
                this.options.cars = result;
                this.scform.car = 0;
            } ).then( () => {
                // could actually also happen in a parallel way, but for loading dots, use then() here
                df.getOfferstoreSettings( this.scform.country.value ).then( ( result ) => {
                    // update mailtext on country change (because settings object differs)
                    this.$refs.rtEditorComponent.editor.setContent( result['default_sc_customer_mail_text'] );
                    this.$refs.loadingComponent.isLoading = false;
                } );
            } );

            this.scform.color = null;
            this.options.colors.length = 0;
            this.price = undefined;
            this.cant_calc = true;
        },
        onCarChange: function () {
            this.$refs.loadingComponent.isLoading = true;

            df.getCar( this.scform.car.id ).then( ( result ) => {
                // sort colors and ip amounts (by price and by amount)
                result['colors'].sort( ( a, b ) =>
                    ( parseFloat( a.price ) > parseFloat( b.price ) ) ? 1 : -1 );

                // sort ipas for later usage (select min ip amount)
                result['initial_payment_amounts'].sort( ( a, b ) =>
                    ( parseFloat( a.amount ) > parseFloat( b.amount ) ) ? 1 : -1 );

                // set default color
                const dc = result['colors'].find( ( c ) => c.id === result['default_color_id'] );
                this.scform.color = !dc ? result['colors'][0] : dc;

                this.options.fixtureDetails = getFixtureDesc(result);

                // set min ip amount
                this.scform.minIpAmount = parseInt( result['initial_payment_amounts'][0].amount );

                // set default ip amount
                this.scform.ipAmount = parseInt( result['initial_payment_amount_default']['amount'] );

                // clear additional fixtures
                this.scform.additionalFixtures = [];

                const fixture_level_data = result['fixturedetails'];
                let levels = [];

                if ( !fixture_level_data['first_level_is_unavailable'] ) {
                    levels.push( { 'value': 1, 'text': 'Silber' } );
                }

                if ( !fixture_level_data['second_level_is_unavailable'] ) {
                    levels.push( { 'value': 2, 'text': 'Gold' } );
                }

                if ( !fixture_level_data['third_level_is_unavailable'] ) {
                    levels.push( { 'value': 3, 'text': 'Platin' } );
                }

                this.options.fixtures = levels;

                // display only 'possible' fixture levels
                this.options.fixtures =
                    this.options.fixtures.filter((fixture) =>
                        this.scform.color.fixture_levels.includes(fixture.value.toString()));

                this.options.colors = result['colors'];

                this.scform.fixture = this.options.fixtures[0];

                // set the price
                showPrice( this );

                const pr = { ...this.price_request, includeDetails: false, additionalFixtures: [] };

                df.getAFPrices( pr ).then( ( result ) => {
                    this.options.availAdditionalFixtures = result['afs'];
                    this.options.availExtraOptions = result['eos'];
                    this.options.deliveryPrice = result['delivery'];
                } );

                df.getChargingEquip( this.scform.car['id'], this.scform.country.value ).then( ( result ) => {
                    this.options.availChargingEquip = result;
                } );

                this.$refs.loadingComponent.isLoading = false;
                this.complete_car = result;
                this.originalPriceResult = null;
            } );

            this.scform.specialDiscount = { name: '', price: 0, };
            this.scform.specialDiscountPercValue = 0.0;
            this.scform.additionalCustomFixture = { type: 'custom', name: '', price: 0 };
            this.cant_calc = false;
        },
        specialDiscountPercChange: function () {
            if ( !this.currentPriceResult ) {
                return;
            }

            if (!this.originalPriceResult) {
                this.originalPriceResult = { ...this.currentPriceResult };
            }

            // use the original price result here to not calc wrong results
            this.scform.specialDiscount.price = this.originalPriceResult['fp_price']['pre'] / 100 * this.scform.specialDiscountPercValue;

            // just show the price, don't call onDataChange
            showPrice( this );
        },
        onDataChange: function ( name = '' ) {
            // stop here if there is no car (obviously).
            // weirdness: vue-numeric triggers so this check is necessary
            if ( !this.scform.car ) {
                return;
            }

            if ( name === 'fixtureLevelChanged' || name === 'distanceChanged' || name === 'termChanged' ||
                name === 'colorChanged' ) {
                // event comes from fixture, km, term or color, so reset additional fixtures
                this.scform.additionalFixtures = [];
                clearDisabledAFState( this, 'summer-tire' );
                clearDisabledAFState( this, 'winter-tire' );

                this.options.fixtureDetails = getFixtureDesc(this.complete_car);
            }

            if ( name === 'colorChanged' ) {
                this.options.fixtures = [...this.options.defaultFixtures];
                this.options.fixtures =
                    this.options.fixtures.filter((fixture) =>
                        this.scform.color.fixture_levels.includes(fixture.value.toString()));
            }

            showPrice( this );

            // make a shallow copy, set includeDetails to false and  remove additionalFixtures before sending
            const pr = { ...this.price_request, includeDetails: false, additionalFixtures: [] };

            df.getAFPrices( pr ).then( ( result ) => {
                this.options.availAdditionalFixtures = result['afs'];
                this.options.availExtraOptions = result['eos'];
                this.options.deliveryPrice = result['delivery'];
            } );
        },
        onIpAmountChange: function ( event ) {
            if ( this.currentUser.is_superstaff ) {
                // allow members of superstaff group to go below minimum ip amount, so stop here
                return;
            }

            const amount = parseInt( event.target.value );

            if ( amount < this.scform.minIpAmount ) {
                showErrorPopup( 'Fehler', 'Die Mindestanzahlung darf nicht unterschritten werden.' );

                // reset to min ip amount
                this.scform.ipAmount = this.scform.minIpAmount;
            }
        },
        nameWithEMail( { name, email } ) {
            return `${ name } — <${ email }>`;
        },
        onSubmitButtonClick: function ( event ) {
            event.preventDefault();
            const text = `Soll der Vertragsentwurf wirklich an <strong>${ this.scform.email }</strong> gesendet werden?`;

            showConfirmPopup( 'Bestätigung', text ).then( ( result ) => {
                if ( result ) {
                    doSubmit( this, this.scform );
                }
            } );
        },
    },
    watch: {
        scform: {
            // watch for data changes inside scform property (e.g. when choosing new lead/customer)
            handler() {
                setButtonStatus( this );
            },
            deep: true,
        },
    },
    computed: {
        curr: function () {
            return getCurrSymbol(this.scform.country.value);
        },
        currName: function () {
            return getCurrName(this.scform.country.value);
        },
        distances: function () {
            if ( this.scform.country.value === 'eng' ) {
                return this.options.milesPerYear;
            }

            return this.options.kmsPerYear;
        },
        distanceName: function () {
            if ( this.scform.country.value === 'eng' ) {
                return 'Meilen pro Jahr';
            }

            return 'Jahreskilometer';
        },
        deliveryString: function () {
            return getDeliveryString( this.currentPriceResult['dt'], this.options.iaInterval );
        }
    },
    components: { RichTextEditor, Multiselect, Grid, Base64FileUpload, Loading, VueNumeric },
    beforeMount() {
        // retrieve current user
        // fails on 403 -> redirect to IC-Service administration (login site)
        df.getUser().then( response => {
            if ( !response.ok ) {
                location.href = `${ process.env.VUE_APP_IC_SERVICE_HOST }/admin/service/`;
            }

            response.json().then( user => {
                this.currentUser = user;
            } );
        } );

        // set initial data
        this.scform.country = this.options.countries[0];

        df.getCars( this.scform.country.value ).then( ( result ) => {
            // sort cars by make
            result.sort( ( a, b ) => ( a.model_variant.model.make.name > b.model_variant.model.make.name ) ? 1 : -1 );

            this.options.cars = result;
            this.scform.car = 0;
        } );

        df.getTags().then( ( result ) => {
            this.options.tags = result;
        } );

        this.scform.termInMonth = this.options.termsInMonth[3];
        this.scform.kmPerYear = this.distances[0];
    },
    mounted() {
        df.getSalesChannels( this.scform.country.value ).then( ( result ) => {
            this.options.saleschannels = result;
        });

        df.getOfferstoreSettings( this.scform.country.value ).then( ( result ) => {
            this.$refs.rtEditorComponent.editor.setContent( result['default_sc_customer_mail_text'] );
        } );

        df.getServiceGlobals( this.scform.country.value ).then( ( result ) => {
            this.options.iaInterval = result['instantly_available'];
        } );

        this.$refs.loadingComponent.isLoading = true;
        df.getLeads().then( ( result ) => {
            this.options.searchData = [];

            for (const item of result) {
                this.options.searchData.push(
                    { ...item, is_lead: 'true', badge_text: 'LEAD' }
                );
            }
        } ).then( () => {
            df.getCustomers().then( ( result ) => {
                for (const item of result) {
                    this.options.searchData.push(
                        { ...item, is_customer: 'true', badge_text: 'KUNDE' }
                    );
                }
                this.$refs.loadingComponent.isLoading = false;
            } );
        } );
    }
}
</script>

<style lang="scss" scoped>
$insta-blue: #44a3d4;

#app {
    font-size: 12px;

    .column {
        display: flex;
        flex-direction: column;
    }

    .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 350px;
        margin: .5rem;

        .price {
            font-size: 20px;
            font-weight: bold;

            &.after-tax {
                font-size: 12px;
            }
        }

        .delivery-time {
            font-size: 16px;
            font-weight: bold;
        }

        .inline-item {
            display: flex;
            align-items: center;
            margin-right: 0.7rem;
            margin-bottom: 0.5rem;

            &.textfield {
                margin-bottom: 0;
            }
        }

        &.full-width {
            width: 100%;
            justify-content: center;

            .editor {
                max-width: 70em;
            }
        }

        &.additional-fixture-row,
        &.charging-equip-row, {
            flex-wrap: nowrap;

            & > div {
                display: inline-block;
                width: 70%;
            }
        }

        .additional-fixture-checkbox-label,
        .charging-equip-checkbox-label {
            margin-right: .3rem;
        }

        .additional-fixture-costs,
        .charging-equip-costs {
            display: inline-block;
            width: 30%;
            text-align: right;
        }

        &.search-row {
            width: 500px;
        }

        &.submit-container {
            margin-top: auto;
            margin-left: auto;
        }
    }

    .customer-grid {
        .customer-message {
            margin-top: 1rem;
        }

        table {
            th, td {
                min-width: 80px;
                padding: .3rem;
                text-align: center;
            }

            td:nth-last-child(-n + 2) {
                div {
                    position: static;
                    width: auto;
                    height: auto;

                    img {
                        width: 30px;
                        height: auto;

                        &:hover {
                            opacity: .6;
                        }
                    }
                }
            }
        }

        pre {
            font-style: italic;
        }
    }
}
</style>
