// import data functions
import * as df from '@/api/data';

export function showPrice(instance) {
    updateCustomFixture(instance);

    instance.scform.additionalFixtures = [
        ...new Map(instance.scform.additionalFixtures.map(item =>
        [item['name'], item])).values()
    ];

    instance.price_request = {
        'carId': instance.scform.car.id,
        'colorId': instance.scform.color.id,
        'countryCode': instance.scform.country.value,
        'fixtureLevel': instance.scform.fixture.value,
        'kilometerPerYear': instance.scform.kmPerYear.value,
        'termInMonths': instance.scform.termInMonth.value,
        'initialPaymentAmount': instance.scform.ipAmount,
        'canton': instance.scform.canton.code,
        'tirePackage': instance.scform.tireService,
        'additionalFixtures': instance.scform.additionalFixtures,
        'extraOptions': instance.scform.extraOptions,
        'chargingEquipment': instance.scform.chargingEquip,
        'delivery': instance.scform.delivery ? instance.options.deliveryPrice : null,
        'specialDiscount': instance.scform.specialDiscount,
        'staffUsername': instance.currentUser.username, // for logging purpose ;-)
        'includeDetails': true,
    }

    df.getPrice(instance.price_request).then((result) => {
        instance.currentPriceResult = result;
    });
}

/**
 * Clear form inputs.
 *
 * @param instance the form
 */
export function clearForm(instance) {
    instance.scform.email = '';
    instance.scform.gender = '';
    instance.scform.titlePrefixed = '';
    instance.scform.titleSuffixed = '';
    instance.scform.company = '';
    instance.scform.firstName = '';
    instance.scform.lastName = '';
    instance.scform.street = '';
    instance.scform.city = '';
    instance.scform.zip = '';
    instance.scform.phone = '';
    instance.scform.mobile = '';
    instance.scform.additionalAttachments = [];
    instance.customerGrid.messages = '';
    instance.scform.sales_channel = 13;
    instance.customerGrid.internalNotices = '';
    instance.customerGrid.showMsg = false;
}

/**
 * Check the form validation and enable/disable contract/offer submit buttons.
 *
 * @param instance
 */
export function setButtonStatus(instance) {
    instance.cant_contract = !(instance.scform.email && instance.scform.sales_channel);
}

/**
 * Write lead values to staffcalc form.
 *
 * @param instance
 * @param form_data
 */
export function fillCustomFormData(instance, form_data) {
    if (form_data['email']) {
        instance.scform.email = form_data['email'];
    }

    if (form_data['is_company']) {
        instance.scform.isCompany = form_data['is_company'];
    }

    if (form_data['fleet_size']) {
        instance.scform.fleetSize = form_data['fleet_size'];
    }

    if ('company_name' in form_data && form_data['company_name']) {
        instance.scform.company = form_data['company_name'];
    }

    if ('gender' in form_data && form_data['gender']) {
        instance.scform.gender = form_data['gender'];
    }

    if ('title_prefixed' in form_data && form_data['title_prefixed']) {
        instance.scform.titlePrefixed = form_data['title_prefixed'];
    }

    if ('title_suffixed' in form_data && form_data['title_suffixed']) {
        instance.scform.titleSuffixed = form_data['title_suffixed'];
    }

    if ('first_name' in form_data && form_data['first_name']) {
        instance.scform.firstName = form_data['first_name']
    }

    if ('last_name' in form_data && form_data['last_name']) {
        instance.scform.lastName = form_data['last_name'];
    }

    if (form_data['street']) {
        instance.scform.street = form_data['street'];
    }

    if (form_data['zip']) {
        let zip = form_data['zip'];

        // make e.g. 'AT-1090' useable
        const actual_code = zip.match(/\d+/g).map(n => parseInt(n));

        if (actual_code) {
            instance.scform.zip = actual_code[0];  // comes as array
        } else {
            instance.scform.zip = '';
        }
    }

    if (form_data['city']) {
        instance.scform.city = form_data['city'];
    }

    if (form_data['phone']) {
        instance.scform.phone = form_data['phone'];
    }

    if (form_data['mobile']) {
        instance.scform.mobile = form_data['mobile'];
    }

    if (form_data['customer_message']) {
        instance.customerGrid.messages = form_data['customer_message'];
    }

    if (form_data['sales_channel']) {
        instance.scform.sales_channel = form_data['sales_channel'];
    }

    if (form_data['internal_comments']) {
        instance.customerGrid.internalNotices = form_data['internal_comments'];
    }
}

/**
 * Handle frontend-related stuff for additional fixture checkboxes.
 * @TODO: refactor to use computed disabled property for tire checkboxes?
 *
 * @param instance
 * @param event
 */
export function handleAFCheckboxesStatus(instance, event) {
    const currentFixtures = instance.scform.additionalFixtures;

    // additional fixtures changed, handle it
    if (currentFixtures.some(af => af.type === 'summer-tire')) {
        // optional summer tire wheelset selected, disable others from same type
        for (const af of instance.options.availAdditionalFixtures) {
            if (af.type === 'summer-tire' && `af_summer-tire_${af.id}` !== event.target.id) {
                document.getElementById(`af_summer-tire_${af.id}`).setAttribute('disabled', 'disabled');
            }
        }
    } else {
        clearDisabledAFState(instance, 'summer-tire');
    }

    if (currentFixtures.some(af => af.type === 'winter-tire')) {
        // optional winter tire wheelset selected, disable others from same type
        for (const af of instance.options.availAdditionalFixtures) {
            if (af.type === 'winter-tire' && `af_winter-tire_${af.id}` !== event.target.id) {
                document.getElementById(`af_winter-tire_${af.id}`).setAttribute('disabled', 'disabled');
            }
        }
    } else {
        clearDisabledAFState(instance, 'winter-tire');
    }
}

/**
 * Clear the disabled state on AF checkboxes.
 *
 * @param instance
 * @param tire_type
 */
export function clearDisabledAFState(instance, tire_type) {
    for (const af of instance.options.availAdditionalFixtures) {
        if (af.type === tire_type) {
            document.getElementById(`af_${tire_type}_${af.id}`).removeAttribute('disabled');
        }
    }
}

/**
 * Update the custom additional fixture item.
 * If correctly set, add it to additional fixtures list, otherwise take care of removing it properly.
 *
 * @param instance
 */
export function updateCustomFixture(instance) {
    if (instance.scform.additionalCustomFixture.price) {
        if (instance.scform.additionalFixtures.some(af => af.type === 'custom')) {
            // custom fixture already added, update it
            for (const [i, af] of instance.scform.additionalFixtures.entries()) {
                if (af.type === 'custom') {
                    instance.scform.additionalFixtures[i] = instance.scform.additionalCustomFixture;
                }
            }
        } else {
            instance.scform.additionalFixtures.push(instance.scform.additionalCustomFixture);
        }
    } else {
        // price not set or 0, so remove potentially custom fixtures
        const index = instance.scform.additionalFixtures.findIndex(af => af.type === 'custom');
        if (index >= 0) {
            instance.scform.additionalFixtures.splice(index, 1);
        }
    }
}
